<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";
  import Teams from '@/services/Teams'
  import modalEditSquad from "@/components/modals/squad/modalEditSquad";
  import modalAddSquad from "@/components/modals/squad/modalAddSquad";
  import modalLinkSquadPlayer from "@/components/modals/squad/modalLinkSquadPlayer";
  import Swal from "sweetalert2";


export default {
  components: { Layout, PageHeader, modalEditSquad, modalAddSquad, modalLinkSquadPlayer },
  page: {
    title: "Team Squad",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Squad",
      items: [
        {
          text: "Teams",
          href: "/",
        },
        {
          text: "Squad",
          active: true,
        },
      ],
      isBusy: false,
      teamCompetitions: [],
      competitionTeams: [],
      selected: '',
      squadtableData: [],
      squadProvidertableData: [],
      squadProviderFiltered: [],
      totalRows: 1,
      totalRows_source: 1,
      currentPage: 1,
      currentPage_source: 1,
      perPage: 10,
      perPage_source: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filter_source: null,
      filterOn: [],
      filterOn_source: [],
      sortBy: "order",
      sortBy_source: "order",
      sortDesc: false,
      sortDesc_source: false,
      team_id: '',
      modalData:{},
      playerData:{},
      playerDataProvider:{},
      squad_functions:[],
      squad_provider:[] ,
      clickedAdd: false,
      validTeam: false,
      showSquadAction: false,
      refreshDataVar: false,
      showFirstModal :false ,
      playerDataFromSource:{},
      showImportManuallyModal : false ,
      player:{},
      player_id:[] ,
      linkPlayerData:[],
      showImportFromSourceModal : false ,
      selectedPlayer: null ,
        playerDataArray : {} ,
        loading : null ,

      fields: [
        {
          key: "id",
          sortable: false,
          class: 'd-none'
        },
        {
          key: "index",
          label: "Order",
          sortable: true,
          class: 'text-center'
        },
        {
          key: "img",
          label: "Photo",
          sortable: false,
        },
        {
          key: "first_name",
          label: "First Name / Last Name",
          sortable: true,
        },
        {
          key: "function",
          label: "Function",
          sortable: false,
          class: 'text-capitalize'
        },
        {
          key: "shirt_number",
          label: "Shirt Number",
          sortable: false,
        },
        {
          key: "source_id",
          label: "Source ID",
          sortable: false,
        },
        {
          key: "source",
          label: "Source",
          sortable: false,
        },
        "action",
      ],
      fields_source: [
        {
          key: "first_name",
          label: "First Name / Last Name",
          sortable: true,
        },
        {
          key: "position",
          label: "Function",
          sortable: false,
          class: 'text-capitalize'
        },
        {
          key: "shirt_number",
          label: "Shirt Number",
          sortable: false,
        },
        "action",
      ],
      appLeague: process.env.VUE_APP_LEAGUE,
      tenant: process.env.VUE_APP_TENANT,
      squad_teams: JSON.parse(process.env.VUE_APP_SQUAD_TEAMS)

    };
  },
  computed: {
      /**
       * Total no. of records
       */
      rows() {
        return this.squadtableData.length;
      },
      rows_source() {
        return this.squadProviderFiltered.length;
      }
  },
  mounted() {
    this.select_team();
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  async created() {
    this.getTeamCompetitions()
  },
  methods:{

    select_team(){
      if(this.squad_teams.length == 1 && this.appLeague == 'false'){
        this.selected = this.squad_teams[0];
        this.getTeamSquad()
      }
    },

            fullname(firstname, lastname){
                return firstname + ' ' + lastname;
            },
            openFirstModal(){
                this.showFirstModal = true ;
            } ,
            async getTeamCompetitions(){
                try {
                    this.teamCompetitions = await Teams.getTeamCompetitions()

        if(this.teamCompetitions.data.competitions.length > 0){
             this.competitionTeams = this.teamCompetitions.data.competitions.reduce((acc, competition) => {
                return acc.concat(competition.teams);
             }, []);

         // if(this.teamCompetitions.data.competitions[0].teams.length > 0){
        //    this.competitionTeams = this.teamCompetitions.data.competitions[0].teams
        //  }
        }

      }catch(error){
        this.error = error.response.data.error ? error.response.data.error : "";
      }
    },

    async getTeamSquad(){

        const teamCode = this.selected;
        var teamObj = '';
        var team_id = '';

        let response = '' ;

        if(this.appLeague == 'true') {
            teamObj = this.competitionTeams.find(s => s.team_code === teamCode);
            team_id = teamObj.team_id;
        } else {
            teamObj = this.squad_teams.find(s => s.team_code === teamCode);
        }

        try {
        if(this.selected !== ''){

          this.squadtableData = []
          this.squadProviderFiltered = []
          this.showSquadAction = false;
          this.validTeam = true;
          this.currentPage = 1
          this.toggleBusy();
            if(this.appLeague == 'true') {
              response = await Teams.getTeamSquad(teamCode,team_id)
            }
            else {
              response = await Teams.getTeamSquad(this.selected)
            }
          if(response.data.squad.length > 0){
            this.squadtableData = response.data.squad[0].players
          }else{
            this.squadtableData = []
          }
          if(response.data) {
            this.squad_functions = response.data.squad_functions
            this.squad_provider = response.data.squad_provider
          }

          if(response.data.squad_provider.length > 0){
            this.squadProvidertableData = response.data.squad_provider
            this.squadProviderFiltered = this.squadProvidertableData.filter(function (players) {  return players.squad_imported === 0});
          }else{
            this.squadProviderFiltered = []
          }

          this.toggleBusy();
          this.showSquadAction = true;
          return this.squadtableData

        }else{
          await Swal.fire("Fail!", 'Team not selected', "warning");
        }
      }catch(error){
        this.error = error.response.data.error ? error.response.data.error : "";
      }
    },

      async pullSquad(player_id) {
          Swal.fire({
              title: "Move player to squad",
              text: "This Player will be moved in the squad!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#34c38f",
              cancelButtonColor: "#f46a6a",
              confirmButtonText: "Move it!",
          }).then(async (result) => {
              if (result.value) {
                  try {
                      this.loading = true;
                      const response = await Teams.pullSquad({
                          player_id: player_id.player_id,
                          type: this.selected,
                      });
                      const res = response.data ? response.data : false;
                      const error = response.data.error ? response.data.error : 'Failed';

                      if (res) {
                          await this.getTeamSquad();
                          this.loading = false;
                          Swal.fire("Moved to squad", "Player is successfully moved to squad!", "success");

                          let playerDataFromSource = player_id;
                          playerDataFromSource.person_id = playerDataFromSource.player_id;

                          const matchingPlayer = this.squadtableData.find((player) => player.person_id === playerDataFromSource.player_id);

                          playerDataFromSource.id = matchingPlayer.id ;
                          playerDataFromSource.function = matchingPlayer.function ;
                          playerDataFromSource.order = matchingPlayer.order ;
                          playerDataFromSource.shirt_number = playerDataFromSource.shirt_number ? playerDataFromSource.shirt_number : -1;

                          this.showImportFromSourceModal = false;



                          const playerDataArray = {
                              id: playerDataFromSource.id,
                              function: playerDataFromSource.function,
                              person_id :playerDataFromSource.person_id ,
                              shirt_number : playerDataFromSource.shirt_number ? playerDataFromSource.shirt_number : -1 ,
                              first_name : playerDataFromSource.first_name ,
                              last_name : playerDataFromSource.last_name ,
                              order : playerDataFromSource.order ,

                          };

                          this.callModalSquadEdit(playerDataArray , []);

                      } else {
                          Swal.fire("Fail!", error, "warning");
                      }
                  } catch (error) {
                      this.error = error.response.data.error ? error.response.data.error : "";
                  }

              }
          });
      } ,




//      async pullSquad(player_id) {
//          try {
//              const result = await Swal.fire({
//                  title: "Move player to squad",
//                  text: "This Player will be moved to the squad!",
//                  icon: "warning",
//                  showCancelButton: true,
//                  confirmButtonColor: "#34c38f",
//                  cancelButtonColor: "#f46a6a",
//                  confirmButtonText: "Move it!",
//              });
//
//              if (result.value) {
//                  try {
//                      const response = await Teams.pullSquad({
//                          player_id: player_id.player_id,
//                          type: this.selected,
//                      });
//
//                      const res = response.data ? response.data : false;
//                      const error = response.data.error ? response.data.error : 'Failed';
//
//                      if (res) {
//                          Swal.fire("Moved to squad", "Player is successfully moved to the squad!", "success");
//
//                          // Now that the player has been moved to the squad, get the updated team squad data.
//                          const squadData = await this.getTeamSquad();
//                          console.log('Squad Data:', squadData);
//
//                          let playerDataFromSource = player_id;
//                          playerDataFromSource.person_id = playerDataFromSource.player_id;
//                          console.log(playerDataFromSource);
//
//                          this.callModalSquadEdit({}, {});
//                      } else {
//                          Swal.fire("Fail!", error, "warning");
//                      }
//                  } catch (error) {
//                      this.error = error.response.data.error ? error.response.data.error : "";
//                  }
//              }
//          } catch (error) {
//              console.error('Error while pulling squad data:', error);
//              // Handle errors appropriately
//          }
//      },




    removeSquadPlayer(id){
      Swal.fire({
        title: "Remove player from squad?",
        text: "This Player will be removed from the squad!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Remove!",
      }).then((result) => {
        if (result.value) {
          try {
            Teams.removeSquadPlayer(id)
              .then((response) => {
                  const res = response.data ? response.data : false;
                  const error = response.data.error ? response.data.error : 'Failed';
                  if(res){
                    this.getTeamSquad()
                    Swal.fire("Remove from squad", "Player is successfully removed from squad!", "success");
                  }else{
                    Swal.fire("Fail!", error, "warning");
                  }
              })

          }catch(error){
            this.error = error.response.data.error ? error.response.data.error : "";
          }
        }
      });
    },


            callModalSquadEdit(item, provider) {
            this.$bvModal.show("squad_edit");

            this.playerData = item ;
            this.playerDataProvider = provider ;

            const providerItem= this.playerDataProvider;

            const personId = this.playerData.person_id;

            let matchingRow = this.playerDataProvider.find(providerItem => providerItem.player_id === personId);


                if (!matchingRow) {
                    matchingRow = [];
                }
            this.modalData = {item,matchingRow};


      },



            callModalSquadAdd(){
                this.clickedAdd = true;
                this.showFirstModal = false ;
                if(this.selected.length == 0){
                    return;
                }
                this.$bvModal.show("squad_add");
                this.team_id = this.selected;
            },
            callImportFromOptaModal(){

                this.showImportFromSourceModal = true;
                this.showFirstModal = false ;

                if(this.selected.length == 0){
                    return;
                }
                this.$bvModal.show("second_modal");
                this.team_id = this.selected;
            },

            /**
             * Search the table data with search input
             */
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            onFiltered_source(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows_source = filteredItems.length;
                this.currentPage_source = 1;
            },

            linkPlayerWSource(player){
              this.linkPlayerData = player
              this.$bvModal.show("linkPlayerWSource");
            }
        },
        middleware: "authentication",
    };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-2">
              <label class="col-md-1 col-form-label">Select Team</label>
              <div class="col-md-3">
                <div class="form-row form-group mb-3">
                  <select v-if="appLeague == 'true'" class="form-select" :class="{'is-invalid': !validTeam && clickedAdd}" v-model="selected" @change="getTeamSquad()" id="selectTeam">
                    <option v-for="team in competitionTeams" :key="team.team_code" :value="team.team_code">{{team.team_name}}</option>
                  </select>
                  <select v-else class="form-select" :class="{'is-invalid': !validTeam && clickedAdd}" v-model="selected" @change="getTeamSquad()" id="selectTeam">
                    <option v-for="team in squad_teams" :key="team" :value="team">{{team}}</option>
                  </select>
                </div>
              </div>
              <!--<div class="col-md-2" v-if="showSquadAction">-->
              <!--<a href="javascript:void(0);" class="btn btn-success waves-effect waves-light" @click="callModalSquadAdd"><i class="mdi mdi-plus me-2"></i> Add Squad Member </a>-->
              <!--</div>-->
              <div class="col-md-2" v-if="showSquadAction">
                <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light" @click="openFirstModal"><i class="mdi mdi-plus me-2"></i> Add Squad Member </a>
              </div>
            </div>
          </div>
        </div>
        <div class="card" v-if="validTeam">
          <div class="card-body">

            <h4 class="card-title">Squad Members</h4>

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                            v-model="filter"
                            type="search"
                            placeholder="Search..."
                            class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>

            <div class="row">
              <div class="col-sm-12">
                <div class="table-responsive mb-0">
                  <b-table
                          :busy="isBusy"
                          :items="squadtableData"
                          :fields="fields"
                          responsive="sm"
                          :per-page="perPage"
                          :current-page="currentPage"
                          :sort-by.sync="sortBy"
                          :sort-desc.sync="sortDesc"
                          :filter="filter"
                          :filter-included-fields="filterOn"
                          show-empty
                          empty-text="No Data Found"
                          @filtered="onFiltered"
                          class="squadTable"
                  >

                    <template #cell(index)="squadtableData">
                      {{ squadtableData.item.order}}
                    </template>

                    <template v-slot:cell(first_name)="squadtableData">
                      <a>{{fullname(squadtableData.item.first_name, squadtableData.item.last_name)}}</a>
                    </template>

                    <template v-slot:cell(img)="squadtableData">
                      <img v-if="squadtableData.item.img" class="img-fluid" :src="squadtableData.item.img" width="100">
                      <img v-else class="img-fluid" src="@/assets/images/image_not_found.png" width="100">
                    </template>

                    <template v-slot:cell(action)="squadtableData">
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                          <a href="javascript:void(0);" class="px-2 text-primary" title="Edit Squad Player" @click="callModalSquadEdit(squadtableData.item , squad_provider)">

                          <!--<a href="javascript:void(0);" class="px-2 text-primary" v-b-tooltip.hover title="Edit" @click="callModalSquadEdit(squadtableData.item)">-->
                            <i class="uil uil-pen font-size-18"></i>
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <a href="javascript:void(0);" class="px-2 text-danger" title="Delete Squad Player" @click="removeSquadPlayer(squadtableData.item.id)">
                            <i class="uil uil-trash-alt font-size-18"></i>
                          </a>
                        </li>
                        <li class="list-inline-item" v-if="squadtableData.item.source == 'Dashboard'">
                          <a href="javascript:void(0);" class="px-2 text-primary" title="Link Member With Source Data" @click="linkPlayerWSource(squadtableData.item)">
                            <i class="uil uil-link font-size-18"></i>
                          </a>
                        </li>
                      </ul>
                    </template>

                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </template>

                  </b-table>

                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!--<div class="row" v-if="validTeam">-->
      <!--<div class="col-12">-->
        <!--<div class="card">-->
          <!--<div class="card-body">-->
            <!--<h4 class="card-title">Available Data From Source</h4>-->

            <!--<div class="row mt-4">-->
              <!--<div class="col-sm-12 col-md-6">-->
                <!--<div class="dataTables_length">-->
                  <!--<label class="d-inline-flex align-items-center">-->
                    <!--Show&nbsp;-->
                    <!--<b-form-select-->
                            <!--v-model="perPage_source"-->
                            <!--size="sm"-->
                            <!--:options="pageOptions"-->
                    <!--&gt;</b-form-select-->
                    <!--&gt;&nbsp;entries-->
                  <!--</label>-->
                <!--</div>-->
              <!--</div>-->
              <!--&lt;!&ndash; Search &ndash;&gt;-->
              <!--<div class="col-sm-12 col-md-6">-->
                <!--<div class="dataTables_filter text-md-end">-->
                  <!--<label class="d-inline-flex align-items-center">-->
                    <!--Search:-->
                    <!--<b-form-input-->
                            <!--v-model="filter_source"-->
                            <!--type="search"-->
                            <!--placeholder="Search..."-->
                            <!--class="form-control form-control-sm ms-2"-->
                    <!--&gt;</b-form-input>-->
                  <!--</label>-->
                <!--</div>-->
              <!--</div>-->
              <!--&lt;!&ndash; End search &ndash;&gt;-->
            <!--</div>-->

            <!--<div class="row">-->
              <!--<div class="col-sm-12">-->
                <!--<div class="table-responsive mb-0">-->
                  <!--<b-table-->
                          <!--:busy="isBusy"-->
                          <!--:items="squadProviderFiltered"-->
                          <!--:fields="fields_source"-->
                          <!--responsive="sm"-->
                          <!--:per-page="perPage_source"-->
                          <!--:current-page="currentPage_source"-->
                          <!--:sort-by.sync="sortBy_source"-->
                          <!--:sort-desc.sync="sortDesc_source"-->
                          <!--:filter="filter_source"-->
                          <!--:filter-included-fields="filterOn_source"-->
                          <!--show-empty-->
                          <!--empty-text="No Data Found"-->
                          <!--@filtered="onFiltered_source"-->
                  <!--&gt;-->

                    <!--<template #table-busy>-->
                      <!--<div class="text-center text-danger my-2">-->
                        <!--<b-spinner class="align-middle"></b-spinner>-->
                        <!--<strong>Loading...</strong>-->
                      <!--</div>-->
                    <!--</template>-->

                    <!--<template v-slot:cell(first_name)="squadProviderFiltered">-->
                      <!--<a>{{fullname(squadProviderFiltered.item.first_name, squadProviderFiltered.item.last_name)}}</a>-->
                    <!--</template>-->

                    <!--<template v-slot:cell(action)="squadProviderFiltered">-->
                      <!--<ul class="list-inline mb-0">-->
                        <!--<li class="list-inline-item">-->
                          <!--<a href="javascript:void(0);" class="px-2 text-primary" v-b-tooltip.hover title="Import PLayer" @click="pullSquad(squadProviderFiltered.item.player_id)">-->
                            <!--<i class="uil uil-upload font-size-18"></i>-->
                          <!--</a>-->
                        <!--</li>-->
                      <!--</ul>-->
                    <!--</template>-->

                  <!--</b-table>-->

                <!--</div>-->
              <!--</div>-->
            <!--</div>-->

            <!--<div class="row">-->
              <!--<div class="col">-->
                <!--<div class="dataTables_paginate paging_simple_numbers float-end">-->
                  <!--<ul class="pagination pagination-rounded mb-0">-->
                    <!--&lt;!&ndash; pagination &ndash;&gt;-->
                    <!--<b-pagination-->
                            <!--v-model="currentPage_source"-->
                            <!--:total-rows="rows_source"-->
                            <!--:per-page="perPage_source"-->
                    <!--&gt;</b-pagination>-->
                  <!--</ul>-->
                <!--</div>-->
              <!--</div>-->
            <!--</div>-->

          <!--</div>-->
        <!--</div>-->
      <!--</div>-->
    <!--</div>-->



    <!-- Dialog Modal for displaying two options for Adding Squad -->
    <b-modal v-model="showFirstModal"  id="first_modal" title="Select a method for add Squad" hide-footer>
      <div class="d-flex justify-content-between">
        <b-button variant="primary" @click="callModalSquadAdd">Add Manually</b-button>
        <b-button  v-if="squadProviderFiltered.length > 0" variant="primary" @click="callImportFromOptaModal">Import from Source</b-button>
      </div>
    </b-modal>

    <!--Modal For Importing player form OPTA-->
    <b-modal v-model="showImportFromSourceModal" id="second_modal" :items="squadProviderFiltered" title="Import from Source" title-class="font-18"  :class="{'modal-faded':loading}" centered hide-footer>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Available Data From Source</h4>
              <select v-model="selectedPlayer" class="form-control form-select" name="home_team_id" type="select">
                <option v-for="player in squadProviderFiltered" :key="player.player_id" :value="player">
                  {{ player.first_name }} {{ player.last_name }} {{player.shirt_number}}
                </option>
              </select>

              <div style="text-align: right;">
                <button class="btn btn-primary" style="margin-top: 20px;" :disabled="!selectedPlayer" @click="pullSquad(selectedPlayer)">Import Player</button>
                <!--{{selectedPlayer}}-->
              </div>
              <div style="text-align: center;"> <b-spinner label="Loading..." v-if="loading"></b-spinner></div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>


    <!-- MODALS -->
    <!--<modalEditSquad :squadData="modalData" :squad_func="this.squad_functions" @onRefresh="getTeamSquad()"></modalEditSquad>-->
    <modalEditSquad :squadData="modalData" :squad_func="this.squad_functions" :squadProvider="this.squad_provider" @onRefresh="getTeamSquad()"></modalEditSquad>

    <modalAddSquad :team="team_id" :squad_func="this.squad_functions" @onRefresh="getTeamSquad()"></modalAddSquad>
    <modalLinkSquadPlayer :squadProvider="squadProviderFiltered" :squadData="this.linkPlayerData" @onRefresh="getTeamSquad()"></modalLinkSquadPlayer>
    <!-- END  MODALS -->

  </Layout>
</template>
