<script>
    import {required} from "vuelidate/lib/validators";
    import Teams from '@/services/Teams'
    import validationMessages from '@/components/validations'

    export default {
        components:{
            validationMessages
        },
        props: ['squadProvider', 'squadData'],
        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),
                submitted: false,
                showModal: false,
                tryingToEdit: false,
                selectedPlayer: '',
                warning: false,
            };
        },
        validations: {
            selectedPlayer: {
                required
            }
        },
        methods: {
            modalShown(){

                const lowerFirstName = this.squadData.first_name.toLowerCase();
                const lowerLastName = this.squadData.last_name.toLowerCase();

                // Search for the player
                const sourcePlayer = this.squadProvider.find(
                    (player) =>
                        player.first_name.toLowerCase().includes(lowerFirstName) &&
                        player.last_name.toLowerCase().includes(lowerLastName)
                );

                if (sourcePlayer !== undefined){
                    this.selectedPlayer = sourcePlayer.player_id
                }else{
                    this.selectedPlayer = ''
                    this.warning = true
                }
            },
            async linkSquadPlayer(){
                this.tryingToEdit = true;
                this.submitted = true;
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToEdit = false;
                    return;
                } else {
                    try {

                        let formData = new FormData()
                        formData.append('player_id', this.selectedPlayer)
                        formData.append('csrf_token', this.csrf_token)
                        formData.append('_method', 'PATCH')

                        await Teams.linkSquadPlayer(this.squadData.id, formData)
                            .then((response) => {

                                if(response.data){
                                    this.successmsg("Change has been saved");
                                    this.closeModal();
                                }else{
                                    this.failedmsg("Change has not been saved");
                                }

                            }).catch(error => {
                                this.error = error.response.data.error ? error.response.data.error : "";
                                this.failedmsg(this.error)
                            }).finally(() => {
                                this.refreshData()
                                this.tryingToEdit = false;
                            })

                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    }
                }
                this.tryingToEdit = false;
            },
            changeProviderPlayer(){
                if(this.selectedPlayer != ''){
                    this.warning = false
                }
            },
            refreshData() {
                this.$emit('onRefresh') //event from parent
            },
            closeModal() {
                this.showModal = false;
            },
            resetProps(){
                this.showModal = false
                this.selectedPlayer=''
                this.warning = false
                this.tryingToEdit = false
                this.submitted = false
            },
        }
    };
</script>


<template>
    <b-modal v-model="showModal" @shown="modalShown" @hidden="resetProps" id="linkPlayerWSource" title="Link Squad Member with Source Provider Data" title-class="font-18" centered>
        <div class="row">
            <div class="col-12">
                <h4 class="card-title">Current source member data: {{squadData.source}}</h4>
            </div>
            <div class="col-sm-6">
                <p class="font-size-16 mb-2"><span class="text-strong">Firstname:</span> {{squadData.first_name}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Shirt Number:</span> {{squadData.shirt_number}}</p>
            </div>
            <div class="col-sm-6">
                <p class="font-size-16 mb-2"><span class="text-strong">Lastname:</span> {{squadData.last_name}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Function:</span> {{squadData.function}}</p>
            </div>
            <hr>
            <div class="col-12">
                <h4 class="card-title">Link member with provider data</h4>
                <p class="text-danger" v-if="warning">Could not match member with the provider, please select member manually</p>
            </div>

            <div class="col-12">
                <select
                        :class="{
                                  'is-invalid': submitted && $v.selectedPlayer.$error,
                                }"
                        class="form-control form-select"
                        type="select"
                        v-model="selectedPlayer"
                        @change="changeProviderPlayer">
                    <option :key="player.player_id" :value="player.player_id" v-for="player in squadProvider">
                        {{ player.first_name }} {{ player.last_name }} {{player.shirt_number}}
                    </option>
                </select>
                <validationMessages :fieldName="'Source Member'" :validationName="$v.selectedPlayer" v-if="submitted"></validationMessages>

            </div>
        </div>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="linkSquadPlayer" :disabled="tryingToEdit">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Submit</b-button>
        </template>
    </b-modal>
</template>